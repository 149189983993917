.homepage-section {
  padding: 0 20px 20px 20px;
}
.homepage-section hr {
  border: 2px solid #464646;
  box-sizing: border-box;
  display: inline-block;
  height: 3px;
  margin: 20px;
  width: 59px;
}
.explore-library-section-wrapper {
  background-color: #faf5ed;
  position: relative;
}
.explore-library-section-wrapper .explore-library-section {
  display: flex;
  height: 425px;
  padding-bottom: 0;
  position: relative;
}
@media (max-width: 999px) {
  .explore-library-section-wrapper .explore-library-section {
    height: 500px;
    overflow: hidden;
  }
}
@media (max-width: 749px) {
  .explore-library-section-wrapper .explore-library-section {
    flex-wrap: wrap;
    height: auto;
    text-align: center;
  }
}
.explore-library-section-wrapper .explore-library-section .explore-library-top {
  max-width: 770px;
  position: relative;
  z-index: 3;
}
@media (max-width: 850px) {
  .explore-library-section-wrapper .explore-library-section .explore-library-top {
    max-width: 550px;
  }
}
@media (max-width: 749px) {
  .explore-library-section-wrapper .explore-library-section .explore-library-top {
    max-width: 100%;
    width: 100%;
  }
}
.explore-library-section-wrapper .explore-library-section .explore-library-top .highlight ul {
  text-align: left;
}
.explore-library-section-wrapper .explore-library-section .explore-library-top h1 {
  margin-bottom: 30px;
}
@media (max-width: 1100px) {
  .explore-library-section-wrapper .explore-library-section .explore-library-top div {
    max-width: 650px;
  }
}
.explore-library-section-wrapper .explore-library-section .explore-library-top a {
  margin: 40px 0;
}
@media (max-width: 749px) {
  .explore-library-section-wrapper .explore-library-section .main-image {
    position: relative;
    width: 100%;
  }
}
.explore-library-section-wrapper .explore-library-section .main-image .girl {
  position: absolute;
  right: 0;
  top: 74px;
  z-index: 2;
}
@media (max-width: 999px) {
  .explore-library-section-wrapper .explore-library-section .main-image .girl {
    right: -100px;
    top: 145px;
  }
}
@media (max-width: 749px) {
  .explore-library-section-wrapper .explore-library-section .main-image .girl {
    display: none;
  }
}
.explore-library-section-wrapper .explore-library-section .main-image .line-svg {
  position: absolute;
  right: 0;
  top: 18px;
  z-index: 1;
}
@media (max-width: 999px) {
  .explore-library-section-wrapper .explore-library-section .main-image .line-svg {
    right: -100px;
    top: 80px;
  }
}
@media (max-width: 749px) {
  .explore-library-section-wrapper .explore-library-section .main-image .line-svg {
    display: none;
  }
}
.explore-library-section-wrapper .explore-library-section .main-image .girl-mobile {
  display: none;
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 749px) {
  .explore-library-section-wrapper .explore-library-section .main-image .girl-mobile {
    display: block;
  }
}
.bts-hero-section {
  background: url('https://cdn.education.com/files/static/bts2020/hero-image-2x.jpg') no-repeat 50%;
  background-size: cover;
  height: 470px;
  display: inline-block;
  width: 100%;
}
.bts-hero-section .page-width {
  padding: 0 30px;
}
.bts-hero-section .page-width h1 {
  font-size: 58px;
  color: #464646;
  text-align: center;
  margin: 30px 0 0 0;
}
.bts-hero-section .page-width .section-text {
  width: 419px;
  font-size: 20px;
  line-height: 1.4;
  color: #313131;
  margin-top: 40px;
}
.bts-hero-section .page-width .btn {
  min-width: 327px;
  margin: 40px 0;
}
@media (max-width: 1199px) {
  .bts-hero-section .page-width h1 {
    font-size: 48px;
  }
}
@media (max-width: 1000px) {
  .bts-hero-section {
    height: 395px;
    background: url('https://cdn.education.com/files/static/bts2020/hero-image-1000-2x.jpg') no-repeat 50%;
    background-size: cover;
  }
  .bts-hero-section .page-width {
    text-align: center;
  }
  .bts-hero-section .page-width .section-text {
    width: 100%;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .bts-hero-section .page-width .btn {
    margin: 20px 0;
    min-width: 250px;
  }
}
@media (max-width: 750px) {
  .bts-hero-section {
    height: 500px;
    background: url('https://cdn.education.com/files/static/bts2020/hero-image-750-2x.jpg') no-repeat 50%;
    background-size: cover;
  }
  .bts-hero-section .page-width {
    text-align: center;
  }
  .bts-hero-section .page-width h1 {
    font-size: 35px;
  }
  .bts-hero-section .page-width .section-text {
    font-size: 18px;
    line-height: normal;
    width: 100%;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .bts-hero-section .page-width .btn {
    min-width: 250px;
    margin: 20px auto;
  }
}
@media (max-width: 542px) {
  .bts-hero-section {
    height: 580px;
  }
}
@media (max-width: 480px) {
  .bts-hero-section {
    height: 406px;
    background: url('https://cdn.education.com/files/static/bts2020/hero-image-360-480-2x.jpg') no-repeat 50%;
    background-size: cover;
  }
  .bts-hero-section .page-width {
    padding: 0 25px;
  }
  .bts-hero-section .page-width h1 {
    font-size: 35px;
  }
  .bts-hero-section .page-width .section-text {
    padding: 0;
    margin-top: 15px;
  }
}
@media (max-width: 382px) {
  .bts-hero-section {
    height: 480px;
  }
}
@media (max-width: 360px) {
  .bts-hero-section .page-width {
    padding: 0 10px;
  }
  .bts-hero-section .page-width h1 {
    font-size: 34px;
  }
}
.carousel-section {
  text-align: center;
  margin-bottom: 60px;
}
.carousel-section h2 {
  margin-top: 0;
}
.carousel-section .carousel-top {
  margin: 70px 0 0 0;
  text-align: center;
}
.carousel-section .carousel-top .textAndButton {
  margin-top: 0;
  white-space: nowrap;
}
.carousel-section .carousel-top .airplane img,
.carousel-section .carousel-top .spacer {
  width: calc(20vw);
}
.carousel-section .carousel-top .airplane {
  margin-left: -20px;
  position: absolute;
}
@media (max-width: 1200px) {
  .carousel-section .carousel-top .airplane,
  .carousel-section .carousel-top .spacer {
    display: none;
    visibility: collapse;
  }
  .carousel-section .carousel-top .textAndButton {
    white-space: normal;
  }
}
@media (max-width: 749px) {
  .carousel-section .carousel-top {
    margin-bottom: 20px;
  }
}
.carousel-section .carousel-top div {
  margin: 20px auto 40px;
}
@media (max-width: 749px) {
  .carousel-section .carousel-top div {
    margin-bottom: 20px;
  }
}
.carousel-section .btn {
  min-width: 327px;
}
.carousel-section .btn-magenta {
  min-width: 219px;
}
.carousel-section .content-types {
  display: flex;
  flex-wrap: wrap;
  max-width: 1150px;
}
.carousel-section .content-type {
  flex: 1;
  text-align: center;
  border: solid 1px #bbbbbb;
  border-radius: 10px;
  margin: 10px;
  display: grid;
  min-width: 410px;
  max-width: 560px;
}
@media (max-width: 1000px) {
  .carousel-section {
    /* We need this so once the content-type divs are shown in a single column they are centered. */
    /* And other responsive changes. */
  }
  .carousel-section .content-types {
    display: inline-block;
  }
  .carousel-section .content-type {
    min-width: 0;
  }
  .carousel-section .btn {
    min-width: 250px;
  }
}
.carousel-section .buttonContainer {
  align-self: end;
  margin-bottom: 31px;
  margin-top: 20px;
  display: inline-block;
  text-align: center;
}
.carousel-section .content-header {
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  color: #00c4c9;
  display: inline-block;
  margin-top: 21px;
}
.carousel-section .content-header img {
  margin-right: 10px;
}
.carousel-section .title {
  display: inline-block;
  transform: translate(0, -35%);
  font-weight: 600;
}
.carousel-section .content-description {
  margin-left: 35px;
  margin-right: 35px;
  font-size: 20px;
}
@media (max-width: 750px) {
  .carousel-section .carousel-top {
    margin-top: 50px;
  }
  .carousel-section .content-header {
    font-size: 30px;
  }
  .carousel-section .content-header img {
    width: 45px;
  }
  .carousel-section .content-header .title {
    vertical-align: text-top;
  }
  .carousel-section .content-description {
    font-size: 18px;
  }
}
@media (max-width: 750px) {
  .carousel-section {
    margin-bottom: 40px;
  }
}
.school-solutions-container.section {
  text-align: center;
}
.school-solutions-container.section .page-width {
  display: flex;
  max-width: none;
}
.school-solutions-container.section img {
  height: 100%;
  max-height: 440px;
}
.school-solutions-container.section .group {
  margin: 0 auto;
}
@media (max-width: 999px) {
  .school-solutions-container.section .page-width {
    max-width: 625px;
    display: block;
  }
  .school-solutions-container.section .center {
    max-width: none;
  }
  .school-solutions-container.section .group {
    display: none;
    visibility: collapse;
  }
}
.school-solutions-container.section .header {
  font-size: 40px;
  font-weight: bold;
}
@media (max-width: 749px) {
  .school-solutions-container.section .header {
    font-size: 30px;
  }
}
.school-solutions-container.section .sub-header {
  max-width: 750px;
  margin: 20px auto;
}
@media (max-width: 750px) {
  .school-solutions-container.section .group {
    display: none;
    visibility: collapse;
  }
}
.school-solutions-container.section .btn {
  min-width: 327px;
}
@media (max-width: 1000px) {
  .school-solutions-container.section .btn {
    min-width: 250px;
  }
}
.school-solutions-container.section h2 {
  margin: 0;
  padding-bottom: 20px;
}
.school-solutions-container.section .button-container {
  margin-top: 50px;
}
.school-solutions-container.section .center {
  max-width: 800px;
  padding-top: 70px;
  padding-bottom: 70px;
}
@media (max-width: 750px) {
  .school-solutions-container.section .center {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.premium-section {
  position: relative;
  background-color: #ffded1;
  text-align: center;
}
.premium-section .btn {
  min-width: 327px;
  margin-bottom: 70px;
}
.premium-section a {
  margin-top: 20px;
}
.premium-section h2 {
  padding-top: 70px;
}
@media (max-width: 750px) {
  .premium-section h2 {
    padding-top: 50px;
  }
  .premium-section .btn {
    margin-bottom: 50px;
  }
}
.premium-section .premimum-full.small {
  visibility: collapse;
  display: none;
}
.premium-section .premium-description {
  display: flex;
  text-align: left;
}
.premium-section .premium-description h4 {
  white-space: nowrap;
  margin-bottom: 20px;
}
.premium-section .premium-description .progressTracker {
  margin-right: 40px;
}
.premium-section .premium-description .progressTracker div {
  max-width: 250px;
}
.premium-section .premium-description .guidedLessons {
  margin-top: 170px;
}
.premium-section .premium-description .guidedLessons div {
  max-width: 250px;
}
.premium-section .highlight {
  margin: 20px auto 40px;
}
.premium-section img.premimum-full {
  width: 594px;
  height: 417px;
}
@media (max-width: 1200px) {
  .premium-section .premium-info {
    display: flex;
  }
  .premium-section .spacer {
    width: 100%;
  }
  .premium-section .premium-description {
    display: block;
  }
  .premium-section .premium-description h4 {
    margin-top: 0;
  }
  .premium-section .premium-description .progressTracker {
    margin-bottom: 10px;
  }
  .premium-section .premium-description .guidedLessons {
    margin-top: 0;
    margin-left: 39%;
  }
  .premium-section .premium-description .highlight {
    margin-left: 0;
  }
}
@media (max-width: 1000px) {
  .premium-section .btn {
    min-width: 250px;
  }
}
@media (max-width: 750px) {
  .premium-section .premimum-full.large {
    visibility: collapse;
    display: none;
  }
  .premium-section .premimum-full.small {
    visibility: visible;
    display: block;
    width: 313px;
    height: 244px;
  }
  .premium-section .progressTracker {
    text-align: center;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .premium-section .progressTracker div {
    max-width: initial !important;
  }
  .premium-section .guidedLessons {
    text-align: center;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .premium-section .guidedLessons div {
    max-width: initial !important;
  }
  .premium-section h4 {
    margin-top: 10px !important;
  }
}
@media (max-width: 360px) {
  .premium-section .premimum-full.small {
    visibility: visible;
    display: block;
    width: 250px;
  }
}
@media (max-width: 1200px) {
  .premium-section a {
    margin-top: 0;
  }
}
.quotes-section {
  background: #5672c4;
  text-align: center;
}
.quotes-section h2 {
  color: white;
  margin: 0;
  padding: 70px 25px 25px 25px;
}
.quotes-section .btn {
  min-width: 327px;
}
.quotes-section .mascotList {
  list-style-type: none;
  display: flex;
  margin-left: calc((100vw - 800px) / 3);
  margin-right: calc((100vw - 800px) / 3);
  padding-inline-start: 0;
  padding-bottom: 40px;
}
@media (max-width: 1000px) {
  .quotes-section .btn {
    min-width: 250px;
  }
}
.quotes-section .mascotList li {
  width: 100%;
}
.quotes-section .mascotList li .bullet {
  display: flex;
  font-size: 20px;
}
.quotes-section .mascotList li .spacer {
  width: 100%;
}
.quotes-section .mascotList li .bulletInfo {
  color: white;
  max-width: 270px;
  min-width: 270px;
}
@media (max-width: 850px) {
  .quotes-section .mascotList li .bullet {
    font-size: 16px;
  }
  .quotes-section .mascotList li .bulletInfo {
    max-width: 230px;
    min-width: 230px;
  }
}
.quotes-section .mascotList li .bullet span {
  color: gold;
  font-weight: bold;
  font-size: 28px;
}
.quotes-section .mascotList li .social.mascot {
  width: 154px;
}
.quotes-section .mascotList li .social.mascot.Floyd {
  margin-top: 6px;
}
.quotes-section .mascotList li .social.mascot.Tutu {
  margin-top: 24px;
}
.quotes-section .quotes-collection {
  background-color: #6380d6;
  display: inline-block;
  margin-bottom: 70px;
  border-radius: 8px;
  max-width: 1130px;
}
.quotes-section .quotes-collection .btn {
  margin-bottom: 30px;
  margin-top: 20px;
}
.quotes-section .quotes-collection .quotes {
  width: 100%;
}
@media (max-width: 750px) {
  .quotes-section h2 {
    padding: 50px 25px 25px 25px;
  }
  .quotes-section .quotes-collection {
    margin-bottom: 50px;
  }
  .quotes-section .mascotList {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .quotes-section .mascotList li {
    margin-bottom: 20px;
  }
  .quotes-section .bulletInfo {
    max-width: 270px;
    min-width: 270px;
  }
}
.quote .single-quote-container {
  max-width: 800px;
  position: relative;
  margin: 0 25px;
  background: white;
  border-radius: 15px;
  margin-top: 40px;
  border: 1px solid #FFF;
}
.quote .quote-message {
  font-size: 20px;
  color: #464646;
  text-align: left;
  padding: 15px;
}
@media (max-width: 750px) {
  .quote .quote-message {
    font-size: 18px;
  }
}
.quote .quote-from {
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
  width: 100%;
}
.quote .quote-attribute {
  font-size: 16px;
}
.quote .quote-left {
  padding-right: 15px;
}
.quote .quote-left .quote-attribute {
  padding-left: 0;
}
.quote .quote-left .quote-from {
  padding-left: 0;
  padding-top: 12px;
}
.quote .quote-triangle:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-top: 12px solid #FFF;
  border-bottom: 12px solid transparent;
  bottom: -24px;
}
.quote .quote-triangle:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-top: 12px solid #FFF;
  border-bottom: 12px solid transparent;
  bottom: -23px;
}
.quote .quote-triangle.left:before {
  border-left: 15px solid transparent;
  border-right: 15px solid #FFF;
  left: 29px;
}
.quote .quote-triangle.left:after {
  border-left: 15px solid transparent;
  border-right: 14px solid #FFF;
  left: 29px;
}
.quote .quote-triangle.right:before {
  border-right: 15px solid transparent;
  border-left: 15px solid #FFF;
  right: 29px;
}
.quote .quote-triangle.right:after {
  border-right: 15px solid transparent;
  border-left: 14px solid #FFF;
  right: 29px;
}
.quote .quote-avatar {
  height: 67px;
  width: 67px;
  overflow: hidden;
  border-radius: 50px;
}
.quote .quote-description {
  max-width: 320px;
  position: relative;
  display: flex;
  margin: 20px 0 0 80px;
  color: white;
}
.quote .quote-description .quote-image {
  border-radius: 100px;
  height: 100%;
  display: block;
  margin: auto;
  margin-left: -11px;
}
.quote .quote-description .quote-right {
  text-align: left;
}
.quote .quote-description .quote-left {
  text-align: right;
}
.quote .quote-description .quote-image-container {
  margin-top: 25px;
}
.quote .quote-description.left {
  float: right;
  margin-right: 85px;
}
.quotes .clear {
  clear: both;
}
@media (min-width: 1000px) {
  .quote {
    margin-right: 350px;
  }
  .quote.adjust {
    margin-left: 350px;
    margin-top: -110px;
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  .quote-description {
    display: block !important;
    margin-top: 20px !important;
    margin-left: 0 !important;
    max-width: none !important;
  }
  .quote-description.left {
    float: none !important;
    margin-right: 0 !important;
    display: flex!important;
    flex-direction: column-reverse;
    max-width: none;
  }
  .quote-left {
    text-align: center !important;
    margin-top: 10px;
  }
  .quote-right {
    text-align: center !important;
  }
  .quote-avatar {
    margin: 0 auto;
  }
}
.join.section {
  background: url('https://cdn.education.com/files/static/homepage/new-fall-2020/Roly-Rocket-1600.png') no-repeat 50%;
  background-size: cover;
  height: 473px;
  display: inline-block;
  width: 100%;
}
.join.section .page-width {
  margin-top: 115px;
}
.join.section h2 {
  color: white;
  margin: 0;
  padding-bottom: 50px;
  font-size: 60px;
  white-space: nowrap;
}
.join.section .btn {
  min-width: 327px;
}
.join.section .buttonAndHeader {
  text-align: center;
  display: inline-block;
  margin-left: 10%;
}
@media (max-width: 1000px) {
  .join.section .btn {
    min-width: 250px;
  }
}
@media (max-width: 750px) {
  .join.section {
    height: 471px;
    background: url('https://cdn.education.com/files/static/homepage/new-fall-2020/Roly-Rocket-750.png') no-repeat 50%;
    background-size: cover;
  }
  .join.section .buttonAndHeader {
    margin-left: 0;
  }
  .join.section h2 {
    font-size: 50px;
  }
}
@media (max-width: 480px) {
  .join.section {
    background: url('https://cdn.education.com/files/static/homepage/new-fall-2020/Roly-Rocket-480.png') no-repeat 50%;
    background-size: cover;
  }
  .join.section .page-width {
    text-align: center;
    margin-top: 50px;
  }
  .join.section .buttonAndHeader {
    display: inline;
  }
}
@media (max-width: 360px) {
  .join.section h2 {
    font-size: 45px;
  }
}
#plane-animation {
  width: 35%;
  height: 300px;
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 550px) {
  #plane-animation {
    display: none;
  }
}
