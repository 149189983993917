@import (reference) "less/variables.less";
@import (reference) "styles/screen-sizes.less";
@import (reference) "styles/colors.less";

.homepage-section {
  padding: 0 20px 20px 20px;

  hr {
    border: 2px solid @color-gray-primary;
    box-sizing: border-box;
    display: inline-block;
    height: 3px;
    margin: 20px;
    width: 59px;
  }
}

.explore-library-section-wrapper {
  background-color: @color-cream;
  position: relative;

  .explore-library-section {
    display: flex;
    height: 425px;
    padding-bottom: 0;
    position: relative;

    @media (max-width: (@screen-md - 1)) {
      height: 500px;
      overflow: hidden;
    }

    @media (max-width: (@screen-sm - 1)) {
      flex-wrap: wrap;
      height: auto;
      text-align: center;
    }

    .explore-library-top {
      max-width: 770px;
      position: relative;
      z-index: 3;

      @media (max-width: (850px)) {
        max-width: 550px;
      }

      @media (max-width: (@screen-sm - 1)) {
        max-width: 100%;
        width: 100%;
      }

      .highlight {
        ul {
          text-align: left;
        }
      }

      h1 {
        margin-bottom: 30px;
      }

      div {
        @media (max-width: (1100px)) {
          max-width: 650px;
        }
      }

      a {
        margin: 40px 0;
      }
    }

    .main-image {
      @media (max-width: (@screen-sm - 1)) {
        position: relative;
        width: 100%;
      }

      .girl {
        position: absolute;
        right: 0;
        top: 74px;
        z-index: 2;

        @media (max-width: (@screen-md - 1)) {
          right: -100px;
          top: 145px;
        }

        @media (max-width: (@screen-sm - 1)) {
          display: none;
        }
      }

      .line-svg {
        position: absolute;
        right: 0;
        top: 18px;
        z-index: 1;

        @media (max-width: (@screen-md - 1)) {
          right: -100px;
          top: 80px;
        }

        @media (max-width: (@screen-sm - 1)) {
          display: none;
        }
      }

      .girl-mobile {
        display: none;
        margin: 0 auto;
        width: 100%;

        @media (max-width: (@screen-sm - 1)) {
          display: block;
        }
      }
    }
  }
}

.bts-hero-section {
  background: url('https://cdn.education.com/files/static/bts2020/hero-image-2x.jpg') no-repeat 50%;
  background-size: cover;
  height: 470px;
  display: inline-block;
  width: 100%;
  .page-width {
    padding: 0 30px;
    h1 {
      font-size: 58px;
      color: #464646;
      text-align: center;
      margin: 30px 0 0 0;
    }
    .section-text {
      width: 419px;
      font-size: 20px;
      line-height: 1.4;
      color: #313131;
      margin-top: 40px;
    }

    .btn {
      min-width: 327px;
      margin: 40px 0;
    }
  }

  @media (max-width: (@screen-desktop - 1)) {
    .page-width {
      h1 {
        font-size: 48px;
      }
    }
  }
  @media (max-width: (@screen-tablet-lg)) {
    height: 395px;
    background: url('https://cdn.education.com/files/static/bts2020/hero-image-1000-2x.jpg') no-repeat 50%;
    background-size: cover;
    .page-width {
      text-align: center;

      .section-text {
        width: 100%;
        text-align: center;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .btn {
        margin: 20px 0;
        min-width: 250px;
      }
    }
  }
  @media (max-width: (@screen-tablet)) {
    height: 500px;
    background: url('https://cdn.education.com/files/static/bts2020/hero-image-750-2x.jpg') no-repeat 50%;
    background-size: cover;
    .page-width {
      text-align: center;
      h1 {
        font-size: 35px;
      }
      .section-text {
        font-size: 18px;
        line-height: normal;
        width: 100%;
        text-align: center;
        padding: 0 10px;
        box-sizing: border-box;
      }
      .btn {
        min-width: 250px;
        margin: 20px auto;
      }
    }
  }

  @media (max-width: 542px) {
    height: 580px;
  }

  @media (max-width: (@screen-phone-lg)) {
    height: 406px;
    background: url('https://cdn.education.com/files/static/bts2020/hero-image-360-480-2x.jpg') no-repeat 50%;
    background-size: cover;
    .page-width {
      padding: 0 25px;
      h1 {
        font-size: 35px;
      }
      .section-text {
        padding: 0;
        margin-top: 15px;
      }
    }
  }

  @media (max-width: 382px) {
    height: 480px;
  }

  @media (max-width: (@screen-phone)) {
    .page-width {
      padding: 0 10px;
      h1 {
        font-size: 34px;
      }
    }
  }
}

.carousel-section {
  text-align: center;
  margin-bottom: 60px;

  h2 {
    margin-top: 0;
  }

  .carousel-top {
    margin: 70px 0 0 0;
    text-align: center;

    .textAndButton {
      margin-top: 0;
      white-space: nowrap;
    }

    .airplane img, .spacer {
      width: calc(20vw);
    }

    .airplane {
      margin-left: -20px;
      position: absolute;
    }

    @media (max-width: (@screen-desktop)) {
      .airplane, .spacer {
        display: none;
        visibility: collapse;
      }

      .textAndButton {
        white-space: normal;
      }
    }

    @media (max-width: (@screen-sm - 1)) {
      margin-bottom: 20px;
    }

    div {
      margin: 20px auto 40px;

      @media (max-width: (@screen-sm - 1)) {
        margin-bottom: 20px;
      }
    }
  }

  .btn {
    min-width: 327px;
  }

  .btn-magenta {
    min-width: 219px;
  }

  .content-types {
    display: flex;
    flex-wrap: wrap;
    max-width: 1150px;
  }

  .content-type {
    flex: 1;

    text-align: center;

    border: solid 1px #bbbbbb;
    border-radius: 10px;
    margin: 10px;

    display: grid;
    min-width: 410px;
    max-width: 560px;
  }

  @media (max-width: @screen-tablet-lg) {
    /* We need this so once the content-type divs are shown in a single column they are centered. */
    /* And other responsive changes. */
    .content-types {
      display: inline-block;
    }

    .content-type {
      min-width: 0;
    }

    .btn {
      min-width: 250px;
    }
  }

  .buttonContainer {
    align-self: end;
    margin-bottom: 31px;
    margin-top: 20px;
    display: inline-block;
    text-align: center;
  }

  .content-header {
    font-size: 35px;
    font-weight: 500;
    text-align: center;
    color: #00c4c9;
    display: inline-block;
    margin-top: 21px;

    img {
      margin-right: 10px;
    }
  }

  .title {
    display: inline-block;
    transform: translate(0, -35%);
    font-weight: 600;
  }

  .content-description {
    margin-left: 35px;
    margin-right: 35px;
    font-size: 20px;
  }

  @media (max-width: @screen-tablet) {
    .carousel-top {
      margin-top: 50px;
    }

    .content-header {
      font-size: 30px;

      img {
        width: 45px;
      }

      .title {
        vertical-align: text-top;
      }
    }

    .content-description {
      font-size: 18px;
    }
  }
}

@media (max-width: @screen-tablet) {
  .carousel-section {
    margin-bottom: 40px;
  }
}

.school-solutions-container.section {
  text-align: center;

  .page-width {
    display: flex;
    max-width: none;
  }

  img {
    height: 100%;
    max-height: 440px;
  }

  .group {
    margin: 0 auto;
  }

  @media (max-width: (@screen-tablet-lg - 1)) {
    .page-width {
      max-width: 625px;
      display: block;
    }

    .center {
      max-width: none;
    }

    .group {
      display: none;
      visibility: collapse;
    }
  }

  .header {
    font-size: 40px;
    font-weight: bold;

    @media (max-width: (@screen-sm - 1)) {
      font-size: 30px;
    }
  }

  .sub-header {
    max-width: 750px;
    margin: 20px auto;
  }

  @media (max-width: @screen-tablet) {
    .group {
      display: none;
      visibility: collapse;
    }
  }

  .btn {
    min-width: 327px;
  }

  @media (max-width: @screen-tablet-lg) {
    .btn {
      min-width: 250px;
    }
  }

  h2 {
    margin: 0;
    padding-bottom: 20px;
  }

  .button-container {
    margin-top: 50px;
  }

  .center {
    max-width: 800px;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @media (max-width: @screen-tablet) {
    .center {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
}

.premium-section {
  position: relative;
  background-color: @color-peach;
  text-align: center;

  .btn {
    min-width: 327px;
    margin-bottom: 70px;
  }

  a {
    margin-top: 20px;
  }

  h2 {
    padding-top: 70px;
  }

  @media (max-width: @screen-tablet) {
    h2 {
      padding-top: 50px;
    }

    .btn {
      margin-bottom: 50px;
    }
  }

  .premimum-full.small {
    visibility: collapse;
    display: none;
  }

  .premium-description {
    display: flex;
    text-align: left;

    h4 {
      white-space: nowrap;
      margin-bottom: 20px;
    }

    .progressTracker {
      margin-right: 40px;

      div {
        max-width: 250px;
      }
    }

    .guidedLessons {
      margin-top: 170px;

      div {
        max-width: 250px;
      }
    }
  }

  .highlight {
    margin: 20px auto 40px;
  }

  img.premimum-full {
    width: 594px;
    height: 417px;
  }

  @media (max-width: @screen-desktop) {
    .premium-info {
      display: flex;
    }

    .spacer {
      width: 100%
    }

    .premium-description {
      display: block;

      h4 {
        margin-top: 0;
      }

      .progressTracker {
        margin-bottom: 10px;
      }

      .guidedLessons {
        margin-top: 0;
        margin-left: 39%;
      }

      .highlight {
        margin-left: 0;
      }
    }
  }

  @media (max-width: @screen-tablet-lg) {
    .btn {
      min-width: 250px;
    }
  }

  @media (max-width: @screen-tablet) {
    .premimum-full.large {
      visibility: collapse;
      display: none;
    }

    .premimum-full.small {
      visibility: visible;
      display: block;
      width: 313px;
      height: 244px;
    }

    .progressTracker {
      text-align: center;
      margin-right: 0 !important;
      margin-left: 0 !important;

      div {
        max-width: initial !important;
      }
    }

    .guidedLessons {
      text-align: center;
      margin-right: 0 !important;
      margin-left: 0 !important;

      div {
        max-width: initial !important;
      }
    }

    h4 {
      margin-top: 10px !important;
    }
  }

  @media (max-width: @screen-phone) {
    .premimum-full.small {
      visibility: visible;
      display: block;
      width: 250px;
    }
  }
}

@media (max-width: @screen-desktop) {
  .premium-section {
    a {
      margin-top: 0;
    }
  }
}

.quotes-section {
  background: @color-blue-sapphire;
  text-align: center;

  h2 {
    color: white;
    margin: 0;
    padding: 70px 25px 25px 25px;
  }

  .btn {
    min-width: 327px;
  }

  .mascotList {
    list-style-type: none;
    display: flex;
    margin-left: calc((100vw - 800px) / 3);
    margin-right: calc((100vw - 800px) / 3);
    padding-inline-start: 0;
    padding-bottom: 40px;
  }

  @media (max-width: @screen-tablet-lg) {
    .btn {
      min-width: 250px;
    }
  }

  .mascotList li {
    width: 100%;

    .bullet {
      display: flex;
      font-size: 20px;
    }

    .spacer {
      width: 100%;
    }

    .bulletInfo {
      color: white;
      max-width: 270px;
      min-width: 270px;
    }

    // Super duper special case so we can still be 3 wide with only two lines of
    // text for each bullet before we go to a single column at 750px wide
    @media (max-width: 850px) {
      .bullet {
        font-size: 16px;
      }

      .bulletInfo {
        max-width: 230px;
        min-width: 230px;
      }
    }

    .bullet span {
      color: gold;
      font-weight: bold;
      font-size: 28px;
    }

    .social.mascot {
      width: 154px;
    }

    .social.mascot.Floyd {
      margin-top: 6px;
    }

    .social.mascot.Tutu {
      margin-top: 24px;
    }
  }

  .quotes-collection {
    background-color: #6380d6;
    display: inline-block;
    margin-bottom: 70px;
    border-radius: 8px;
    max-width: 1130px;

    .btn {
      margin-bottom: 30px;
      margin-top: 20px;
    }

    .quotes {
      width: 100%
    }
  }

  @media (max-width: @screen-tablet) {
    h2 {
      padding: 50px 25px 25px 25px;
    }

    .quotes-collection {
      margin-bottom: 50px;
    }

    .mascotList {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }

    .mascotList li {
      margin-bottom: 20px;
    }

    .bulletInfo {
      max-width: 270px;
      min-width: 270px;
    }
  }
}

.quote {
  .single-quote-container {
    max-width: 800px;
    position: relative;
    margin: 0 25px;
    background: white;
    border-radius: 15px;
    margin-top: 40px;
    border: 1px solid @color-white;
  }

  .quote-message {
    font-size: 20px;
    color: @color-gray-primary;
    text-align: left;
    padding: 15px;
  }

  @media (max-width: @screen-tablet) {
    .quote-message {
      font-size: 18px;
    }
  }

  .quote-from {
    font-size: 16px;

    font-weight: bold;
    padding-top: 5px;
    width: 100%;
  }

  .quote-attribute {
    font-size: 16px;
  }

  .quote-left {
    padding-right: 15px;
  }

  .quote-left .quote-attribute {
    padding-left: 0;
  }

  .quote-left .quote-from {
    padding-left: 0;
    padding-top: 12px;
  }

  .quote-triangle:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-top: 12px solid @color-white;
    border-bottom: 12px solid transparent;
    bottom: -24px;
  }

  .quote-triangle:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-top: 12px solid @color-white;
    border-bottom: 12px solid transparent;
    bottom: -23px;
  }

  .quote-triangle.left:before {
    border-left: 15px solid transparent;
    border-right: 15px solid @color-white;
    left: 29px;
  }

  .quote-triangle.left:after {
    border-left: 15px solid transparent;
    border-right: 14px solid @color-white;
    left: 29px;
  }

  .quote-triangle.right:before {
    border-right: 15px solid transparent;
    border-left: 15px solid @color-white;
    right: 29px;
  }

  .quote-triangle.right:after {
    border-right: 15px solid transparent;
    border-left: 14px solid @color-white;
    right: 29px;
  }

  .quote-avatar {
    height: 67px;
    width: 67px;
    overflow: hidden;
    border-radius: 50px;
  }

  .quote-description {
    max-width: 320px;
    position: relative;
    display: flex;
    margin: 20px 0 0 80px;
    color: white;

    .quote-image {
      border-radius: 100px;
      height: 100%;
      display: block;
      margin: auto;
      margin-left: -11px;
    }

    .quote-right {
      text-align: left;
    }

    .quote-left {
      text-align: right;
    }

    .quote-image-container {
      margin-top: 25px;
    }
  }

  .quote-description.left {
    float: right;
    margin-right: 85px;
  }
}

.quotes .clear {
  clear: both;
}

@media (min-width: @screen-tablet-lg) {
  .quote {
    margin-right: 350px;
  }

  .quote.adjust {
    margin-left: 350px;
    margin-top: -110px;
    margin-right: 0;
  }
}

@media (max-width: @screen-phone-lg) {
  .quote-description {
    display: block !important;
    margin-top: 20px !important;
    margin-left: 0 !important;
    max-width: none !important;;
  }

  .quote-description.left {
    float: none !important;
    margin-right: 0 !important;
    display: flex!important;
    flex-direction: column-reverse;
    max-width: none;
  }

  .quote-left {
    text-align: center !important;
    margin-top: 10px;
  }

  .quote-right {
    text-align: center !important;
  }

  .quote-avatar {
    margin: 0 auto;
  }
}

.join.section {
  background: url('https://cdn.education.com/files/static/homepage/new-fall-2020/Roly-Rocket-1600.png') no-repeat 50%;
  background-size: cover;
  height: 473px;
  display: inline-block;
  width: 100%;

  .page-width {
    margin-top: 115px;
  }

  h2 {
    color: white;
    margin: 0;
    padding-bottom: 50px;
    font-size: 60px;
    white-space: nowrap;
  }

  .btn {
    min-width: 327px;
  }

  .buttonAndHeader {
    text-align: center;
    display: inline-block;
    margin-left: 10%;
  }

  @media (max-width: (@screen-tablet-lg)) {
    .btn {
      min-width: 250px;
    }
  }

  @media (max-width: (@screen-tablet)) {
    height: 471px;
    background: url('https://cdn.education.com/files/static/homepage/new-fall-2020/Roly-Rocket-750.png') no-repeat 50%;
    background-size: cover;

    .buttonAndHeader {
      margin-left: 0;
    }

    h2 {
      font-size: 50px;
    }
  }

  @media (max-width: (@screen-phone-lg)) {
    background: url('https://cdn.education.com/files/static/homepage/new-fall-2020/Roly-Rocket-480.png') no-repeat 50%;
    background-size: cover;

    .page-width {
      text-align: center;
      margin-top: 50px;
    }

    .buttonAndHeader {
      display: inline;
    }
  }

  @media (max-width: (@screen-phone)) {
    h2 {
      font-size: 45px;
    }
  }
}

#plane-animation {
  width: 35%;
  height: 300px;
  position: absolute;
  left: 0;
  bottom: 0;

  @media (max-width: (550px)) {
    display: none;
  }
}


